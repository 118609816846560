import axios from "axios";
const url = "api/v1/admin/";
class AdminService{
    static getInformationUsers(user,qtr) {
        let formData = {
          user:user,
          qtr:qtr
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
    
        return  axios.post(url + "brgdm", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static sendNotification(user,seller,title,subtitle,message) {
        let formData = {
          user:user,
          seller:seller,
          title:title,
          subtitle:subtitle,
          message:message
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
    
        return  axios.post(url + "sndn", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getTimeZones(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
    
        return  axios.post("api/v1/user/tzlst", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static insertTimeZone(user,zone,company) {
        let formData = {
          user:user,
          zone:zone,
          company:company
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
    
        return  axios.post("api/v1/user/svtz", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static insertDesign(user,ux,title) {
        let formData = {
          user:user,
          ux:ux,
          title:title
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
    
        return  axios.post(url + "svdsn", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }
      static getDesigns(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.put(url + "svdsn", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getEmailDetails(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "brstmg", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getLastSign(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "brslgs", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getFeedbacks(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post(url + "gfbck", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      static getMysteryBoxGained(user) {
        let formData = {
          user:user
        }
        axios.defaults.headers.post["Content-Type"] = "application/json";
        return  axios.post("/api/v1/strd/msx/brtmb", formData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

}
export default AdminService;