<template>
  <div>
    
    <b-card class="border">
      <b-card-header class="p-1">
        <b-card-title>Mystery Box Gained</b-card-title>
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Filter by Search"   
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
               Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-card-header>
          <b-table
          :busy="isBusy"
          hover
        striped
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        selectable
        select-mode="range"
        >
          <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
       <template #cell(username)="data">
        <b-badge
        variant="warning"
        >
         <feather-icon
        icon="AwardIcon"
        class="mr-25"
      />
       <span>{{data.value}}</span>
        </b-badge>
      </template>
        </b-table>
       
           <b-col cols="12">
       <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              vari
            />
    </b-col>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BTable,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BRow,
  BCol,
  BPagination,
  BSpinner,
  BBadge
} from "bootstrap-vue";
import AdminService from "@/services/AdminService"
export default {
  components: { BCard, BCardHeader, BCardTitle, BCardText, BTable,BFormGroup,BInputGroup,BFormInput,BInputGroupAppend,
  BButton,BRow,BCol,BPagination,BSpinner,BBadge },
  data() {
    return {
      isBusy:true,
      fields: [{label:'username',key:'username'},{label:'DiscordID',key:'discoruserid'},{label:'Item Title',key:'title'},
      {label:'Item Description',key:'description'}],
      items: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    };
  },
  mounted(){
     this.getMysteryBoxGained()
  },
  methods:{
      onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getMysteryBoxGained:async function(){
        const mbg=(await AdminService.getMysteryBoxGained(JSON.parse(localStorage.getItem("auth")).discordId)).data
        this.items=mbg
         this.totalRows = this.items.length
         this.isBusy=false
    }
  }
};
</script>